import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Back from "../../../common/components/back";
import { ROUTES_PATH } from "../../../constant";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import ContentTable from "../ContentTable/contentTable";
import {approveStatus, idRename, fourRename } from "../../../utils/rename";
import Footer from "../../Footer";
import DeleteModal from "../deleteModal";
import FilterDropdown from "../../commonComponent/FilterDropdown";
import EditHospitalModal from "../../commonComponent/EditHospitalModal";

const Pictures = (props) => {
  const handleBack = () => {
    props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
  };

  const handleAdd = () => {
    props.history.push(ROUTES_PATH.ADD_PICTURES)
  }

  useEffect(() => {
    props.toggleMainLoader(true)
    props.getAwaitingPictures("AWAITING", 1);
    props.getScheduledPictures("SCHEDULED", 1);
    props.getPublishedPictures("PUBLISHED", 1);
  }, [])

  const [awaitingPictures, setAwaitingPictures] = useState([])
  const [scheduledPicture, setScheduledPicturea] = useState([])
  const [publishedPicture, setPublishedPicture] = useState([])
  const [awaitingPage, setAwaitingPage] = useState(props.awaitingPageNumber)
  const [scheduledPage, setScheduledPage] = useState(props.scheduledPageNumber)
  const [publishedPage, setPublishedPage] = useState(props.publishPageNumber)
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [sort, setSort] = useState();
  const [scheduledSort, setScheduledSort] = useState();
  const [awaitingSort, setAwaitingSort] = useState();
  const [show, setShow] = useState(false);
  const [hospitalData, setHospitalData] = useState([])

  //useEffect to refresh the data when an action in called
  useEffect(() => {
    props.toggleMainLoader(true);
    if(props.awaitingPageNumber){
    props.getAwaitingPictures("AWAITING", awaitingPage, awaitingSort);
    }
    if(props.scheduledPageNumber){
    props.getScheduledPictures("SCHEDULED", scheduledPage, scheduledSort);
    }
    if(props.publishPageNumber){
    props.getPublishedPictures("PUBLISHED", publishedPage, sort);
    }
    setDeleteIndex(0)
    setDeleteModal(false)
    props.successResponse(false);
  }, [props.responseSuccess, awaitingSort, scheduledSort, sort ]);


  const headerAwaiting = {
    headerId: 'Id',
    headerOne: "Picture Title",
    headerTwo: "Description",
    headerThree: "Actions",
  };
  const headerScheduled = {
    headerId: 'Id',
    headerOne: "Picture Title",
    headerTwo: "Description",
    headerThree: "Scheduled Date",
  };
  const headerPublished = {
    headerId: 'Id',
    headerOne: "Picture Title",
    headerTwo: "Description",
    headerThree: "Updated Date",
  };

  useEffect(() => {
    setAwaitingPictures(idRename(
      props.awaitingPictures.results,
      "id",
      "cellOne",
      "cellTwo",
      "term",
      "meaning"
    ))
  }, [props.awaitingPictures.results]);

  useEffect(() => {
    setScheduledPicturea(
      fourRename(
        props.scheduledPictures.results,
        "id",
        "term",
        "meaning",
        "scheduled_date"
      )
    );
  }, [props.scheduledPictures.results]);

  useEffect(() => {
    setPublishedPicture(
      fourRename(
        props.publishedPictures.results,
        "id",
        "term",
        "meaning",
        "updated_at"
      )
    );
  }, [props.publishedPictures.results]);


  const awaitingPageChange = (select) => {
    setAwaitingPage(select);
    props.toggleMainLoader(true);
    props.getAwaitingPictures("AWAITING", select, awaitingSort);
  };
  const scheduledPageChange = (select) => {
    setScheduledPage(select);
    props.toggleMainLoader(true);
    props.getScheduledPictures("SCHEDULED", select, scheduledSort);
  };
  const publishedPageChange = (select) => {
    setPublishedPage(select);
    props.toggleMainLoader(true);
    props.getPublishedPictures("PUBLISHED", select, sort);
  };

  const picturesApprove = (index) => {
    const status = approveStatus(props.awaitingPictures.results.filter((item) => item.id === index)[0].scheduled_date)
    props.picturesAction(index, { status: status });
  };

  const picturesReject = (index) => {
    props.picturesAction(index, { status: "REJECT" });
  };

  //Functions for Edit and delete
  const handleDelete = (index) => {
    setDeleteIndex(index)
    setDeleteModal(true)
  }
  const handleDeleteClose = () => {
    setDeleteIndex(0)
    setDeleteModal(false)
  }
  const handleDeleteQuotes = () => {
    props.deletePicture(deleteIndex)
  }
  const handleUpdate = (data) => {
    props.contentDetail(data)
    props.history.push(ROUTES_PATH.EDIT_PICTURE)
    props.PublishProcedurePage(publishedPage)
    props.awaitingProcedurePage(awaitingPage)
    props.scheduledProcedurePage(scheduledPage)
  }

  const handleHospitalUpdate = (data) => {
    setHospitalData(data);
    setShow(true);
  };

  const handleHospitalUpdateClose = () => {
    setShow(false);
  }

  return (
    <>
      <Container className="common-container">
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />
        <UserManagementNavbar
          title={"Pictures"}
          isEditButton={false}
          isDeleteButton={false}
          id={"parent-nav-title"}
          className={"parent-navbar"}
          isAddButton={true}
          buttonTitle={"Add Picture"}
          handleAddButton={handleAdd}
        />

        <ContentTable
          mainHeader={"Published"}
          secondHeader = {<FilterDropdown  sort = {setSort} value = {sort}/>}
          header={headerPublished}
          dataList={publishedPicture}
          itemCountFromServer={props.publishedPictures.count}
          rowPerPage={10}
          pageNumber={publishedPage}
          changePage={publishedPageChange}
          minHeightClass={"procedures-minHeight"}
          contentHeaderID = {'content-header-id'}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-header2"}
          contentHeaderThree={"procedures-header3"}
          contentCellOne={"procedures-cell1"}
          contentCellTwo={"procedures-cell2"}
          contentCellThree={"procedures-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
          hospitalEdit={true}
          hospitalEditModal = {handleHospitalUpdate}
        />

        <div style={{ marginTop: "39px" }}></div>
        
        <ContentTable
          mainHeader={"Content Awaiting Approval"}
          secondHeader = {<FilterDropdown  sort = {setAwaitingSort} value = {awaitingSort}/>}
          header={headerAwaiting}
          dataList={awaitingPictures}
          onApprove={picturesApprove}
          onReject={picturesReject}
          itemCountFromServer={props.awaitingPictures.count}
          rowPerPage={10}
          pageNumber={awaitingPage}
          changePage={awaitingPageChange}
          minHeightClass={"procedures-minHeight"}
          contentHeaderID = {'content-header-id'}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-header2"}
          contentHeaderThree={"procedures-header3"}
          contentCellOne={"procedures-cell1"}
          contentCellTwo={"procedures-cell2"}
          contentCellThree={"procedures-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}

        />
        <div style={{ marginTop: "39px" }}></div>

        <ContentTable
          mainHeader={"Scheduled"}
          secondHeader = {<FilterDropdown  sort = {setScheduledSort} value = {scheduledSort}/>}
          header={headerScheduled}
          dataList={scheduledPicture}
          itemCountFromServer={props.scheduledPictures.count}
          rowPerPage={10}
          pageNumber={scheduledPage}
          changePage={scheduledPageChange}
          minHeightClass={"procedures-minHeight"}
          contentHeaderID = {'content-header-id'}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-header2"}
          contentHeaderThree={"procedures-header3"}
          contentCellOne={"procedures-cell1"}
          contentCellTwo={"procedures-cell2"}
          contentCellThree={"procedures-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}

        />
        

        <EditHospitalModal 
        show={show}
        title = {"Update Hospital"}
        contentClassName={"modal-content-quotes"}
        handleCancelClose = {handleHospitalUpdateClose}
        hospitalData = {hospitalData}
        setShow = {setShow}
        isPicture = {true}
        />
        

        <DeleteModal modal={deleteModal}
          handleClose={handleDeleteClose}
          handleDelete={handleDeleteQuotes}
          loader={props.loader}
        />
      </Container>
      <Footer className={"content-management-footer"} />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    responseSuccess: state.responseSuccess.responseSuccess,
    awaitingPictures: state.pictures,
    scheduledPictures: state.scheduledPictures,
    publishedPictures: state.publishedPictures,
    loader: state.loader.mainLoader,
    publishPageNumber: state.PublishedProcedurePage.pageNumber,
    awaitingPageNumber: state.AwaitingPageReducer.awaitingPageNumber,
    scheduledPageNumber :  state.ScheduledPageReducer.scheduledPageNumber,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAwaitingPictures: actionCreator.getPictures,
      getScheduledPictures: actionCreator.getScheduledPictures,
      getPublishedPictures: actionCreator.getPublishedPictures,
      toggleMainLoader: actionCreator.toggleMainLoader,
      picturesAction: actionCreator.pictureAction,
      successResponse: actionCreator.responseSuccess,
      deletePicture: actionCreator.deletePicture,
      contentDetail: actionCreator.getContentDetails,
      PublishProcedurePage : actionCreator.getPublishProcedurePage,
      awaitingProcedurePage : actionCreator.getAwaitingPage,
      scheduledProcedurePage : actionCreator.getScheduledPage,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Pictures);
