import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "react-bootstrap";
import "../ContentManagement/DailyQuotes/dailyQuotes.css";
import "../UserManagement/UserManagementClinicianDetails/userManagementClinicianDetails.css";
import * as actionCreator from "../../modules/actions";
import MultiSelect from "../UserManagement/multiSelect";
import { rename } from "../../utils/rename";
import { components } from "react-select";
import { clone } from "lodash";


const Option = (props) => {
  return (
    <components.Option {...props}>
      <div>
        {props.children}
        <input
          className="option-custom-input"
          type="radio"
          checked={props.isSelected}
          onChange={(e) => null}
        ></input>
      </div>
    </components.Option>
  );
};

const HospitalDropdown = (props) => {
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [options, setOptions] = useState([]);
  const [hospitalInvalid, setHospitalInvalid] = useState(false);

  const [data, setData] = useState({
    hospital: "",
    hospitalList: [],
  });

  useEffect(() => {
    if (
      props?.hospitalData?.hospital_detail &&
      props?.hospitalData?.hospital_detail.length > 0 &&
      options.length > 0
    ) {
      const hospital = options.find(
        (option) => option.value === parseInt(props.hospitalData.hospital_detail[0].id)
      );
      setData({
        hospital: hospital ? hospital.value : "",
        hospitalList: hospital ? [hospital] : [],
      });
    }
  }, [options, props?.hospitalData?.hospital_detail]);

  useEffect(() => {
    props.getFullHospitals();
  }, []);

  useEffect(() => {
    setOptions(
      rename(props.fullHospitals.data, "value", "label", "id", "name")
    );
  }, [props.fullHospitals.data]);

  const setHospital = (selectedOption) => {
    const hospital = selectedOption ? selectedOption.value : "";
    props?.setHospitalFilterData(hospital)
    setHospitalInvalid(false);
    setData({
      hospital: hospital,
      hospitalList: selectedOption ? [selectedOption] : [],
    });
  };



  const dropDownStyles = {
    control: (base, state) => ({
      ...base,
      border: submittedOnce && hospitalInvalid ? "1px solid #eb5757" : base.border,
      boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(0 123 255 / 25%)" : 0,
      marginTop: "13px",
      backgroundColor: "#455674",
      color : "#fff !important",
      borderRadius: "8px !important",
      "&:hover": {
        border: submittedOnce && hospitalInvalid ? "1px solid #eb5757" : base.border,
        backgroundColor: "#455674",
        color : "#fff !important",
        borderRadius: "8px !important",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      width: "100%",
      maxWidth: "592px",
      height: "34px",
      backgroundColor: "#455674",
      borderRadius: "12px",
      boxSizing: "border-box",
      border: "none",
      marginTop: "2px",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#fff !important",
      wordBreak: "break-word",
    }),
    placeholder: (base) => ({
      ...base,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      color: "#fff",
    }),
    indicatorsContainer: (base) => ({
        ...base,
        backgroundColor: "#455674",
        border: "none",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        color: "#AEB0B5",
        outline: "none",
        borderRadius: "8px !important",
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: "#fff",
        "&:hover": {
          color: "#fff",
        },
      }),
      clearIndicator: (base) => ({
        ...base,
        color: "#fff",
        cursor: "pointer",
        "&:hover": {
          color: "#fff",
          cursor : "pointer",
        },
      }),
    option: (base) => ({
      ...base,
      borderBottom: "1px solid #AEB0B5",
      color: "#455674",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "16px",
      paddingBottom: "16px",
      backgroundColor: "none",
      paddingLeft: "20px",
      
    }),
    singleValue: (base) => ({
      ...base,
      color: "#fff", // Change the text color of the selected option to white
    }),
  };

  return (
    <Form.Group controlId="formBasicEmail">
      <MultiSelect
        multi={false}
        placeholder={"Hospital Filter"}
        options={options}
        styles={dropDownStyles}
        components={{ Option }}
        className="select-hospital-class"
        hideSelectedOptions={false}
        closeMenuOnSelect={true}
        onChange={setHospital}
        value={data.hospitalList}
        isClearable={true}
      />
      {submittedOnce && hospitalInvalid && (
        <p className="clinician-invalid">Please choose a hospital</p>
      )}
    </Form.Group>
  );
};

const mapStateToProps = (state) => {
  try {
    return {
      user: state.user.profile,
      details: state.userManagementDetails.details,
      loader: state.loader.mainLoader,
      resourcesCategory: state.resourcesCategory,
      contentDetails: state.contentDetails,
      fullHospitals: state.fullHospitals,
      userRole: state.user.profile.role,
      hospitalDetails: state.hospitalDetails,
    };
  } catch (e) {
    console.log(e);
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      getFullHospitals: actionCreator.getFullHospitals,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HospitalDropdown);
