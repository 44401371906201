import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ROUTES_PATH } from "../../../constant";
import Back from "../../../common/components/back";
import Footer from "../../Footer";
import ContentTable from "../ContentTable/contentTable";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { Container } from "react-bootstrap";
import { fourRename } from "../../../utils/rename";
import "../Procedures/procedures.css";
import AddResources from "./addResources";
import DeleteModal from "../deleteModal";
import FilterDropdown from "../../commonComponent/FilterDropdown";
import EditHospitalModal from "../../commonComponent/EditHospitalModal";
import { getParentResourcesService } from "../../../modules/services";
import { toast } from "react-toastify";

const ParentResources = props => {
  const [publishedPageNumber, setPublishedPage] = useState(1);
  const [publishedDataList, setPublishedList] = useState();
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [sort, setSort] = useState();
  const [showHospitalModal, setShowHospitalModal] = useState(false);
  const [hospitalData, setHospitalData] = useState([])
  const [hospitalFilterData, setHospitalFilterData] = useState('');
  const [csvLoader, setCsvLoader] = useState(false)

  useEffect(() => {
    props.reset();
    props.toggleMainLoader(true);
    props.getPublishedResources("PUBLISHED", publishedPageNumber, sort, props.role?.includes('SUPERUSER') ? props?.user?.hospital_id : hospitalFilterData );
    setPublishedPage(publishedPageNumber);
    if (props.responseSuccess) {
      setDeleteIndex(0);
      setDeleteModal(false);
      setShow(false);
    }
  }, [props.match.params.path, props.responseSuccess, sort, hospitalFilterData]);

  useEffect(() => {
    setPublishedList(
      fourRename(props.publishedResources.results, "id","pdf_title", "updated_at")
    );
  }, [props.publishedResources.results]);


  useEffect(() => {
    if(props.openModal){
      setShow(true);
      setEdit(true);
      props.openSearchModal(false)
    }
  }, [])

  const headerPublished = {
    headerId: 'Id',
    headerOne: "Resource Title",
    headerTwo: "Updated Date",
  };

  const changePagePublished = selected => {
    setPublishedPage(selected);
    props.toggleMainLoader(true);
    props.getPublishedResources("PUBLISHED", selected, sort, props.role?.includes('SUPERUSER') ? props?.user?.hospital_id : hospitalFilterData );
  };

  const handleBack = () => {
    props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
  };
  const handleAddButton = () => {
    props.toggleMainLoader(false);
    setShow(true);
    setEdit(false);
  };

  const handleCancelClose = () => {
    setShow(false);
  };

  //Functions for Edit and delete
  const handleDelete = index => {
    setDeleteIndex(index);
    setDeleteModal(true);
  };
  const handleDeleteClose = () => {
    setDeleteIndex(0);
    setDeleteModal(false);
  };
  const handleDeleteQuotes = () => {
    props.toggleMainLoader(true);
    props.deleteResources(deleteIndex);
  };

  const handleUpdate = data => {
    props.contentDetails(data);
    setEdit(true);
    setShow(true);
  };

  const handleUpdateClose = () => {
    setShow(false);
    setEdit(false);
  };

  const handleHospitalUpdate = (data) => {
    setHospitalData(data);
    setShowHospitalModal(true);
    props.contentDetails(data);
  };

  const handleHospitalUpdateClose = () => {
    setShowHospitalModal(false);
  }

  const handelDownloadCsv = () => {
    setCsvLoader(true)
    getParentResourcesService("PUBLISHED", publishedPageNumber, sort, props.role?.includes('SUPERUSER') ? props?.user?.hospital_id : hospitalFilterData, true)
    .then(response =>{
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `parent_resources.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setCsvLoader(false)
        toast.success("CSV file downloaded successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(err=> {
      console.error(err)
      setCsvLoader(false)
      toast.error("Download Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
  }

  return (
    <>
      <Container
        style={{
          minHeight: "100vh",
          position: "relative",
          maxWidth: "1046px",
        }}
      >
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />
        <UserManagementNavbar
          title={"Parent Resources"}
          isEditButton={false}
          isDeleteButton={false}
          id={"parent-nav-title"}
          className={"parent-navbar"}
          isAddButton={true}
          isHospitalFilter = {props.role.includes("ADMIN") ? true : false}
          buttonTitle={"Add Resource"}
          handleAddButton={handleAddButton}
          setHospitalFilterData = {setHospitalFilterData}
          // isCsvButton = {true}
          // handelDownloadCsv = {handelDownloadCsv}
          // csvLoader = {csvLoader}
        />

        <ContentTable
          mainHeader={"Published"}
          secondHeader = {<FilterDropdown  sort = {setSort} value = {sort}/>}
          header={headerPublished}
          dataList={publishedDataList}
          rowPerPage={10}
          itemCountFromServer={props.publishedResources.count}
          changePage={changePagePublished}
          pageNumber={publishedPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderID = {'content-header-id'}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-scheduled-header2"}
          contentHeaderThree={"procedures-scheduled-header3"}
          contentCellOne={"procedures-scheduled-cell1"}
          contentCellTwo={"procedures-scheduled-cell2"}
          contentCellThree={"procedures-scheduled-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
          removeCellThree = {true}
          isCellTwoDate = {true}
          hospitalEdit={true}
          hospitalEditModal = {handleHospitalUpdate}
          isCsvButton = {true}
          handelDownloadCsv = {handelDownloadCsv}
          csvLoader = {csvLoader}
        />
        <Footer className={"content-management-footer"} />
        <AddResources
          show={show}
          contentClassName={"modal-content-quotes"}
          title={edit ? "Update Parent Resource" : "Add Parent Resources"}
          handleCancelClose={edit ? handleUpdateClose : handleCancelClose}
          isEdit={edit}
          isAddResources={true}
        />

        <DeleteModal
          modal={deleteModal}
          handleClose={handleDeleteClose}
          handleDelete={handleDeleteQuotes}
          loader={props.loader}
        />
        <EditHospitalModal 
          show={showHospitalModal}
          title = {"Update Hospital"}
          contentClassName={"modal-content-quotes"}
          handleCancelClose = {handleHospitalUpdateClose}
          hospitalData = {hospitalData}
          setShow = {setShowHospitalModal}
          isParentResource = {true}
        />
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    responseSuccess: state.responseSuccess.responseSuccess,
    publishedResources: state.publishedParentResourcesReducer,
    loader: state.loader.mainLoader,
    openModal : state.modalFromSearch.openModal,
    role: state.user.profile.role,
    user: state?.user?.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getPublishedResources: actionCreator.getPublishedParentResources,
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      approveResources: actionCreator.approveResources,
      deleteResources: actionCreator.deleteParentResources,
      contentDetails: actionCreator.getContentDetails,
      openSearchModal : actionCreator.isOpenModalFromSearch,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentResources);
