import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { Container } from "react-bootstrap";
import { ROUTES_PATH } from "../../../constant";
import Back from "../../../common/components/back";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import Footer from "../../Footer";
import ContentTable from "../ContentTable/contentTable";
import "./procedures.css";
import { fourRename, approveStatus } from "../../../utils/rename";
import DeleteModal from "../deleteModal";
import FilterDropdown from "../../commonComponent/FilterDropdown";
import EditHospitalModal from "../../commonComponent/EditHospitalModal";
import { getProceduresService} from "../../../modules/services";
import { toast } from "react-toastify";

const Procedures = props => {
  const { role, title, addPath, addEditPath } = props;
  const [awaitingPageNumber, setAwaitingPage] = useState(props.awaitingPageNumber);
  const [publishedPageNumber, setPublishedPage] = useState(props.publishProcedurePageNumber);
  const [scheduledPageNumber, setScheduledPage] = useState(props.scheduledPageNumber);
  const [awaitingDataList, setAwaitingList] = useState();
  const [scheduledDataList, setScheduledList] = useState();
  const [publishedDataList, setPublishedList] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [sort, setSort] = useState();
  const [scheduledSort, setScheduledSort] = useState();
  const [awaitingSort, setAwaitingSort] = useState();
  const [show, setShow] = useState(false);
  const [hospitalData, setHospitalData] = useState([]);
  const [publishedCsvLoader, setPublishedCsvLoader] = useState(false);
  const [awaitingCsvLoader, setAwaitingCsvLoader] = useState(false);
  const [scheduledCsvLoader, setScheduledCsvLoader] = useState(false);


  useEffect(() => {
    props.reset();
    props.toggleMainLoader(true);
    if(props.awaitingPageNumber){
    props.getProcedures("AWAITING", awaitingPageNumber, { for_role: role ? role : "PARENT" }, awaitingSort);
    }
    props.toggleMainLoader(true);

    if(props.publishProcedurePageNumber){
      props.getPublishedProcedures("PUBLISHED", publishedPageNumber, {
        for_role: role ? role : "PARENT",
      }, sort);
      
    }
    
    props.toggleMainLoader(true);

    if(props.scheduledPageNumber){
    props.getScheduledProcedures("SCHEDULED", scheduledPageNumber, {
      for_role: role ? role : "PARENT",
    }, scheduledSort);
  }

    setAwaitingPage(awaitingPageNumber);
    setScheduledPage(scheduledPageNumber);
    setPublishedPage(publishedPageNumber);
    setDeleteIndex(0);
    setDeleteModal(false);
  }, [props.match.params.path, props.responseSuccess, awaitingSort, sort, scheduledSort]);

  useEffect(() => {
    setAwaitingList(
      fourRename(
        props.procedures.results,
        "id",
        "name",
        "uploaded_by",
        "scheduled_date"
      )
    );
  }, [props.procedures.results]);

  useEffect(() => {
    setScheduledList(
      fourRename(
        props.scheduledProcedures.results,
        "id",
        "name",
        "uploaded_by",
        "scheduled_date"
      )
    );
  }, [props.scheduledProcedures.results]);

  useEffect(() => {
    setPublishedList(
      fourRename(
        props.publishedProcedures.results,
        "id",
        "name",
        "uploaded_by",
        "updated_at"
      )
    );
  }, [props.publishedProcedures.results]);

  const headerAwaiting = {
    headerId: 'Id',
    headerOne: "Procedure Name",
    headerTwo: "Uploaded By",
    headerThree: "Actions",
  };
  const headerScheduled = {
    headerId: 'Id',
    headerOne: "Procedure Name",
    headerTwo: "Uploaded By",
    headerThree: "Scheduled Date",
  };
  const headerPublished = {
    headerId: 'Id',
    headerOne: "Procedure Name",
    headerTwo: "Uploaded By",
    headerThree: "Updated Date",
  };

  const changePageAwaiting = selected => {
    setAwaitingPage(selected);
    props.toggleMainLoader(true);
    props.getProcedures("AWAITING", selected, {
      for_role: role ? role : "PARENT",
    }, awaitingSort);
  };

  const changePageScheduled = selected => {
    setScheduledPage(selected);
    props.toggleMainLoader(true);
    props.getScheduledProcedures("SCHEDULED", selected, {
      for_role: role ? role : "PARENT",
    }, scheduledSort);
  };

  const changePagePublished = selected => {
    setPublishedPage(selected);
    props.toggleMainLoader(true);
    props.getPublishedProcedures("PUBLISHED", selected, {
      for_role: role ? role : "PARENT",
    }, sort);
  };

  const handleBack = () => {
    props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
  };
  const handleAddButton = () => {
    props.history.push(addPath ? addPath : ROUTES_PATH.ADD_PROCEDURES);
  };

  const handleApprove = index => {
    props.approveProcedure(index, {
      status: approveStatus(
        props.procedures.results.filter(item => item.id == index)[0]
          .scheduled_date
      ),
    });
  };

  const handleReject = index => {
    props.approveProcedure(index, { status: "REJECT" });
  };

  //FUNCTION TO EDIT AND DELETE
  const handleDelete = index => {
    setDeleteIndex(index);
    setDeleteModal(true);
  };
  const handleDeleteClose = () => {
    setDeleteIndex(0);
    setDeleteModal(false);
  };
  const handleDeleteProcedure = () => {
    props.deleteProcedure(deleteIndex);
  };

  const handleUpdate = data => {
    props.contentDetail(data);
    props.history.push(addEditPath ? addEditPath : ROUTES_PATH.EDIT_PROCEDURE);
    props.PublishProcedurePage(publishedPageNumber)
    props.awaitingProcedurePage(awaitingPageNumber)
    props.scheduledProcedurePage(scheduledPageNumber)
  };

  const handleHospitalUpdate = (data) => {
    setHospitalData(data);
    setShow(true);
  };

  const handleHospitalUpdateClose = () => {
    setShow(false);
  }

  const handlePublishedDownloadCsv = () => {
    setPublishedCsvLoader(true);
    getProceduresService("PUBLISHED", publishedPageNumber, {
      for_role: role ? role : "PARENT",
    }, sort, true)
    .then(response =>{
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `published_procedure.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setPublishedCsvLoader(false)
        toast.success("CSV file downloaded successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(err=> {
      console.error(err)
      setPublishedCsvLoader(false)
      toast.error("Download Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
}

const handelAwaitingDownloadCsv = () => {
  setAwaitingCsvLoader(true);
  getProceduresService("AWAITING", awaitingPageNumber, { for_role: role ? role : "PARENT" }, awaitingSort, true)
  .then(response =>{
    if (response.status === 200 || response.status === 201) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `awaiting_procedure.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setAwaitingCsvLoader(false)
      toast.success("CSV file downloaded successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  })
  .catch(err=> {
    console.error(err)
    setAwaitingCsvLoader(false)
    toast.error("Download Failed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  })
}

const handelScheduledDownloadCsv = () => {
  setScheduledCsvLoader(true);
  getProceduresService("SCHEDULED", scheduledPageNumber, {
      for_role: role ? role : "PARENT",
    }, scheduledSort, true)
.then(response =>{
  console.log("here")
  if (response.status === 200 || response.status === 201) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `scheduled_procedure.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setScheduledCsvLoader(false)
    toast.success("CSV file downloaded successfully.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
})
.catch(err=> {
  console.error(err)
  setScheduledCsvLoader(false)
  toast.error("Download Failed", {
    position: toast.POSITION.TOP_RIGHT,
  });
})
}


  return (
    <>
      <Container
        style={{
          minHeight: "100vh",
          position: "relative",
          maxWidth: "1046px",
        }}
      >
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />
        <UserManagementNavbar
          title={title ? title : "Procedure(Parent)"}
          isEditButton={false}
          isDeleteButton={false}
          id={"parent-nav-title"}
          className={"parent-navbar"}
          isAddButton={true}
          buttonTitle={"Add Procedure"}
          handleAddButton={handleAddButton}
        />
        <ContentTable
          mainHeader={"Published"}
          secondHeader = {<FilterDropdown  sort = {setSort} value = {sort}/>}
          header={headerPublished}
          dataList={publishedDataList}
          rowPerPage={10}
          itemCountFromServer={props.publishedProcedures.count}
          changePage={changePagePublished}
          pageNumber={publishedPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderID = {'content-header-id'}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-scheduled-header2"}
          contentHeaderThree={"procedures-scheduled-header3"}
          contentCellOne={"procedures-scheduled-cell1"}
          contentCellTwo={"procedures-scheduled-cell2"}
          contentCellThree={"procedures-scheduled-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
          hospitalEdit={true}
          hospitalEditModal = {handleHospitalUpdate}
          isCsvButton = {true}
          handelDownloadCsv = {handlePublishedDownloadCsv}
          csvLoader = {publishedCsvLoader}
        />

        <div style={{ marginTop: "39px" }}></div>
        
        <ContentTable
          mainHeader={"Awaiting"}
          secondHeader = {<FilterDropdown  sort = {setAwaitingSort} value = {awaitingSort}/>}
          header={headerAwaiting}
          dataList={awaitingDataList}
          rowPerPage={10}
          itemCountFromServer={props.procedures.count}
          changePage={changePageAwaiting}
          pageNumber={awaitingPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderID = {'content-header-id'}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-header2"}
          contentHeaderThree={"procedures-header3"}
          contentCellOne={"procedures-cell1"}
          contentCellTwo={"procedures-cell2"}
          contentCellThree={"procedures-cell3"}
          viewCellClass={"view-cell-class"}
          onApprove={handleApprove}
          onReject={handleReject}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
          isCsvButton = {true}
          handelDownloadCsv = {handelAwaitingDownloadCsv}
          csvLoader = {awaitingCsvLoader}
        />
        <div style={{ marginTop: "39px" }}></div>
        <ContentTable
          mainHeader={"Scheduled"}
          secondHeader = {<FilterDropdown  sort = {setScheduledSort} value = {scheduledSort}/>}
          header={headerScheduled}
          dataList={scheduledDataList}
          rowPerPage={10}
          itemCountFromServer={props.scheduledProcedures.count}
          changePage={changePageScheduled}
          pageNumber={scheduledPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderID = {'content-header-id'}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-scheduled-header2"}
          contentHeaderThree={"procedures-scheduled-header3"}
          contentCellOne={"procedures-scheduled-cell1"}
          contentCellTwo={"procedures-scheduled-cell2"}
          contentCellThree={"procedures-scheduled-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
          isCsvButton = {true}
          handelDownloadCsv = {handelScheduledDownloadCsv}
          csvLoader = {scheduledCsvLoader}
        />
        
        
        <DeleteModal
          modal={deleteModal}
          handleClose={handleDeleteClose}
          handleDelete={handleDeleteProcedure}
          loader={props.loader}
        />
        <EditHospitalModal 
        show={show}
        title = {"Update Hospital"}
        contentClassName={"modal-content-quotes"}
        handleCancelClose = {handleHospitalUpdateClose}
        hospitalData = {hospitalData}
        setShow = {setShow}
        isProcedure = {true}
        />

        <Footer className={"content-management-footer"} />
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    details: state.userManagementDetails.details,
    responseSuccess: state.responseSuccess.responseSuccess,
    procedures: state.procedures,
    scheduledProcedures: state.scheduledProcedures,
    publishedProcedures: state.publishedProcedures,
    loader: state.loader.mainLoader,
    publishProcedurePageNumber: state.PublishedProcedurePage.pageNumber,
    awaitingPageNumber: state.AwaitingPageReducer.awaitingPageNumber,
    scheduledPageNumber :  state.ScheduledPageReducer.scheduledPageNumber,
    procedure: state.contentDetails,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getProcedures: actionCreator.getProcedures,
      getScheduledProcedures: actionCreator.getScheduledProcedures,
      getPublishedProcedures: actionCreator.getPublishedProcedures,
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      approveProcedure: actionCreator.approveProcedure,
      deleteProcedure: actionCreator.deleteProcedure,
      contentDetail: actionCreator.getContentDetails,
      PublishProcedurePage : actionCreator.getPublishProcedurePage,
      awaitingProcedurePage : actionCreator.getAwaitingPage,
      scheduledProcedurePage : actionCreator.getScheduledPage,
      updateProcedure: actionCreator.updateProcedure
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Procedures);
