import React from "react";
import { Col, Row, Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import "../../commonComponent/commonComponent.css";
import "./contentTable.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import Pagination from "react-js-pagination";
import moment from "moment";
import FullPageLoader from "../../commonComponent/mainLoaderSpinner";
import {
  CONTENT_APPROVE_ICON,
  CROSS_ICON,
  EDIT_ICON,
  RED_DELETE_ICON,
  EDIT_HOSPITAL,
  MESSAGE,
  BUTTON_SPINNER
} from "../../../constant";

const ContentTable = props => {
  const {
    header,
    rowPerPage,
    pageNumber,
    changePage,
    itemCountFromServer,
    mainHeader,
    dataList,
    minHeightClass,
    contentCellOne,
    contentCellTwo,
    contentCellThree,
    contentHeaderOne,
    contentHeaderTwo,
    contentHeaderThree,
    onApprove,
    onReject,
    viewCell,
    onDelete,
    onEdit,
    healthHero,
    isEdit,
    category,
    removeCellThree = false,
    isCellTwoDate = false,
    secondHeader,
    hospitalEdit,
    hospitalEditModal,
    isCsvButton,
    handelDownloadCsv,
    csvLoader,
    loaderImg,
    loaderheight,
    contentHeaderID
  } = props;

  return (
    <>
      <div style={{ marginTop: "18px" }}>
        <Row className="content-main-header">
          <Col className="content-main-header1">{mainHeader}</Col>
          <Col className="content-main-header2" >
          {secondHeader}

          {
            isCsvButton && (

                <button
                  className="csv-button-css"
                  onClick={handelDownloadCsv}
                >
                  {" "}
                  {csvLoader && (
                    <img src={loaderImg} height={loaderheight} alt="" />
                  )}{" "}
                  {!csvLoader && (
                    <span>
                      {" "}
                      Download CSV
                    </span>
                  )}{" "}
                </button>
            )
          }
          </Col>
        </Row>
        <div className="content-main1">
          <div className={minHeightClass}>
            <Table className="mx-auto" responsive="sm">
              <thead>
                <tr className="content-table-row">
                {contentHeaderID && <th className={contentHeaderID}>{header.headerId}</th>}
                  <th className={contentHeaderOne}>{header.headerOne}</th>
                  <th className={contentHeaderTwo}>{header.headerTwo}</th>
                  {header.headerThree ? (
                    <th className={contentHeaderThree}>{header.headerThree}</th>
                  ) : (
                    <th style={{ border: "none" }}></th>
                  )}
                </tr>
              </thead>

              <tbody>
                {dataList.length ? (
                  dataList.map((user, index) => {
                    return (
                      <tr key={index}>
                       {contentHeaderID && <td className={contentCellOne}>{user?.id}</td>}
                        {healthHero ? (
                          <>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip id="tooltip-top">
                                  <div className="mb-1">
                                    Gender : {user.gender ? user.gender : "NA"}
                                  </div>
                                  <div className="mb-1">
                                    About Me:{" "}
                                    {user.about_me ? user.about_me : "NA"}
                                  </div>
                                  <div className="mb-1">
                                    {" "}
                                    Favourite Color:{" "}
                                    {user.favorite_color
                                      ? user.favorite_color
                                      : "NA"}
                                  </div>
                                  <div className="mb-1">
                                    Favourite Movie:{" "}
                                    {user.favorite_movie
                                      ? user.favorite_movie
                                      : "NA"}
                                  </div>
                                  <div className="mb-1">
                                    Favourite Quote:{" "}
                                    {user.favorite_quote
                                      ? user.favorite_quote
                                      : "NA"}
                                  </div>
                                  <div className="mb-1">
                                    {" "}
                                    Favourite Food:{" "}
                                    {user.favorite_food
                                      ? user.favorite_food
                                      : "NA"}
                                  </div>
                                </Tooltip>
                              }
                            >
                              <td className={contentCellOne}>{user.cellOne}</td>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <>
                            <td className={contentCellOne}>{user.cellOne?.slice(0,30)}</td>
                          </>
                        )}
                        <td className={contentCellTwo}>{isCellTwoDate ? moment(user.cellTwo).format("MMMM DD , YYYY"): user.cellTwo != "No" ? user.cellTwo : ""}</td>
                        <td className={contentCellThree}>
                          {header.headerThree === "Actions" ? (
                            <>
                              <button
                                className="approve-css"
                                onClick={() => {
                                  onApprove(user.id);
                                }}
                              >
                                <span>
                                  <img
                                    style={{ paddingRight: "11px" }}
                                    src={CONTENT_APPROVE_ICON}
                                    alt=""
                                  ></img>
                                </span>
                                Approve
                              </button>
                              <button
                                className="reject-css"
                                onClick={() => {
                                  onReject(user.id);
                                }}
                              >
                                <span>
                                  <img
                                    style={{ paddingRight: "11px" }}
                                    src={CROSS_ICON}
                                    alt=""
                                  ></img>
                                </span>
                                Reject
                              </button>
                            </>
                          ) : (user.cellThree && !removeCellThree) ? (
                            user.cellThree
                          ) : null}
                        </td>
                        {viewCell ? (
                          <td className="cell4">
                            {props.userRole.includes("ADMIN") ?
                            hospitalEdit && (
                             <button
                             className="content-edit"
                             onClick={() => {
                               hospitalEditModal(user, category);
                             }}
                           >
                             <img src={EDIT_HOSPITAL} style={{height:"25px"}} alt=""></img>
                           </button>
                           ) : null}
                            {isEdit !== false && (
                              <button
                                className="content-edit"
                                onClick={() => {
                                  onEdit(user , category);
                                }}
                              >
                                <img src={EDIT_ICON} alt=""></img>
                              </button>
                            )}
                            <button
                              className="content-edit"
                              onClick={() => {
                                onDelete(user.id);
                              }}
                            >
                              <img src={RED_DELETE_ICON} alt=""></img>
                            </button>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })
                ) : props.loader.mainLoader ? (
                  <FullPageLoader className="spinner-pos" />
                ) : (
                  <div className="no-results">No results found!!</div>
                )}
                {props.loader.mainLoader ? (
                  <FullPageLoader className="spinner-pos" />
                ) : null}
              </tbody>
            </Table>
          </div>
          {dataList.length ? (
            <Pagination
              totalItemsCount={itemCountFromServer}
              itemsCountPerPage={rowPerPage}
              activePage={pageNumber}
              onChange={changePage}
              prevPageText={"< Prev"}
              nextPageText={"Next >"}
              firstPageText={""}
              lastPageText={""}
              innerClass={"pagination-container"}
              linkClassPrev={"pagination-previous"}
              linkClassNext={"pagination-next"}
              disabledClass={"pagination-link-disabled"}
              activeLinkClass={"pagination-active"}
              linkClass={"page-link-className-css"}
              pageRangeDisplayed={3}
            />
          ) : (
            <div style={{ height: "65px" }}></div>
          )}
        </div>
      </div>
    </>
  );
};

ContentTable.defaultProps = {
  header: { headerOne: "", headerTwo: "", headerThree: "" },
  dataList: [],
  showLoader: false,
  loaderheight: "30px",
  loaderImg: BUTTON_SPINNER,
};
const mapStateToProps = state => {
  return {
    loader: state.loader,
    userRole: state.user.profile.role,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleMainLoader: actionCreator.toggleMainLoader,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentTable);
