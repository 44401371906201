import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Back from "../../../common/components/back";
import Footer from "../../Footer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { ROUTES_PATH } from "../../../constant";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import ContentTable from "../ContentTable/contentTable";
import {rename, newRename, fourRename, idRename } from "../../../utils/rename";
import FilterDropdown from "../../commonComponent/FilterDropdown";
import { getHealthHeroes} from "../../../modules/services";
import { toast } from "react-toastify";

const HealthHeroes = (props) => {
  const [scheduledHealthHeroes, setScheduledHealthHeroes] = useState([]);
  const [publishedHealthHeroes, setPublishedHealthHeroes] = useState([]);
  const [scheduledPage, setScheduledPage] = useState(1);
  const [publishedPage, setPublishedPage] = useState(1);
  const [sort, setSort] = useState();
  const [awaitingSort, setAwaitingSort] = useState();
  const [publishedCsvLoader, setPublishedCsvLoader] = useState(false);
  const [awaitingCsvLoader, setAwaitingCsvLoader] = useState(false);

  const handleBack = () => {
    props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
  };

  //header for awaiting health heroes
  const awaitingHeader = {
    headerId: 'Id',
    headerOne: "Health Hero Name",
    headerTwo: "Specialization",
    headerThree: "Actions",
  };

  //header for published health heroes
  const publishedHeader = {
    headerId: 'Id',
    headerOne: "Health Hero Name",
    headerTwo: "Specialization",
    headerThree: "Updated Date",
  };

  //useEffect to get data when page load
  useEffect(() => {
    props.getHealthHeroes("AWAITING", 1);
    props.getPublishedHealthHeroes("PUBLISHED", 1);
  }, []);

  //useEffect to update data after action call
  useEffect(() => {
    props.toggleMainLoader(true);
    props.getHealthHeroes("AWAITING", scheduledPage, awaitingSort);
    props.getPublishedHealthHeroes("PUBLISHED", publishedPage, sort);
    props.successResponse(false);
  }, [props.responseSuccess, sort, awaitingSort]);

  useEffect(() => {
    setScheduledHealthHeroes(
      idRename(
        props.healthHeroes.results,
        'id',
        "cellOne",
        "cellTwo",
        "name",
        "specialization"
      )
    );
  }, [props.healthHeroes.results]);

  useEffect(() => {
    setPublishedHealthHeroes(
      fourRename(
        props.publishedHealthHeroes.results,
        'id',
        "name",
        "specialization",
        "updated_at"
      )
    );
  }, [props.publishedHealthHeroes.results]);

  //Awaiting HealthHeroes Function Start
  const awaitingPageChange = (select) => {
    setScheduledPage(select);
    props.toggleMainLoader(true);
    props.getHealthHeroes("AWAITING", select, awaitingSort);
  };

  const healthHeroesApprove = (index) => {
    props.healthHeroesAction(index, { status: "PUBLISHED" });
  };

  const healthHeroesReject = (index) => {
    props.healthHeroesAction(index, { status: "REJECT" });
  };
  //AWaiting HealthHeroes  function end

  //pagination function for published health heroes
  const publishedPageChange = (select) => {
    setPublishedPage(select);
    props.toggleMainLoader(true);
    props.getPublishedHealthHeroes("PUBLISHED", select, sort);
  };

  const handlePublishedDownloadCsv = () => {
    setPublishedCsvLoader(true);
    getHealthHeroes("PUBLISHED", publishedPage, sort, true)
    .then(response =>{
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `published_health_heroes.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setPublishedCsvLoader(false)
        toast.success("CSV file downloaded successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(err=> {
      console.error(err)
      setPublishedCsvLoader(false)
      toast.error("Download Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
}

const handelAwaitingDownloadCsv = () => {
  setAwaitingCsvLoader(true);
  getHealthHeroes("AWAITING", scheduledPage, awaitingSort, true)
  .then(response =>{
    if (response.status === 200 || response.status === 201) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `awaiting_health_heroes.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setAwaitingCsvLoader(false)
      toast.success("CSV file downloaded successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  })
  .catch(err=> {
    console.error(err)
    setAwaitingCsvLoader(false)
    toast.error("Download Failed", {
      position: toast.POSITION.TOP_RIGHT,
    });
  })
}


  return (
    <>
      <Container className={"common-container"}>
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />

        <UserManagementNavbar
          title={"Health Hero"}
          id={"parent-nav-title"}
          className={"parent-navbar"}
        />
        <ContentTable
          mainHeader={"Previously Added"}
          secondHeader = {<FilterDropdown  sort = {setSort} value = {sort}/>}
          header={publishedHeader}
          dataList={publishedHealthHeroes}
          itemCountFromServer={props.publishedHealthHeroes.count}
          rowPerPage={10}
          pageNumber={publishedPage}
          changePage={publishedPageChange}
          minHeightClass={"procedures-minHeight"}
          contentHeaderID = {'content-header-id'}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-header2"}
          contentHeaderThree={"procedures-header3"}
          contentCellOne={"procedures-cell1"}
          contentCellTwo={"procedures-cell2"}
          contentCellThree={"procedures-cell3"}
          viewCellClass={"view-cell-class"}
          isCsvButton = {true}
          handelDownloadCsv = {handlePublishedDownloadCsv}
          csvLoader = {publishedCsvLoader}
        />
        <div style={{ marginTop: "39px" }}></div>
        <ContentTable
          healthHero={true}
          mainHeader={"Awaiting Approval"}
          secondHeader = {<FilterDropdown  sort = {setAwaitingSort} value = {awaitingSort}/>}
          header={awaitingHeader}
          dataList={scheduledHealthHeroes}
          onApprove={healthHeroesApprove}
          onReject={healthHeroesReject}
          itemCountFromServer={props.healthHeroes.count}
          rowPerPage={10}
          pageNumber={scheduledPage}
          changePage={awaitingPageChange}
          minHeightClass={"procedures-minHeight"}
          contentHeaderOne={"content-header1"}
          contentHeaderID = {'content-header-id'}
          contentHeaderTwo={"procedures-header2"}
          contentHeaderThree={"procedures-header3"}
          contentCellOne={"procedures-cell1"}
          contentCellTwo={"procedures-cell2"}
          contentCellThree={"procedures-cell3"}
          viewCellClass={"view-cell-class"}
          isCsvButton = {true}
          handelDownloadCsv = {handelAwaitingDownloadCsv}
          csvLoader = {awaitingCsvLoader}
        />
        
        
      </Container>
      <Footer className={"content-management-footer"} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    healthHeroes: state.healthHeroes,
    publishedHealthHeroes: state.publishedHealthHeroes,
    responseSuccess: state.responseSuccess.responseSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHealthHeroes: actionCreator.getHealthheroes,
      healthHeroesAction: actionCreator.healthHeroAction,
      successResponse: actionCreator.responseSuccess,
      toggleMainLoader: actionCreator.toggleMainLoader,
      getPublishedHealthHeroes: actionCreator.getPublishedHealthHeroes,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthHeroes);
