import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ROUTES_PATH } from "../../../constant";
import Back from "../../../common/components/back";
import Footer from "../../Footer";
import ContentTable from "../ContentTable/contentTable";
import UserManagementNavbar from "../../UserManagement/userManagementNavbar";
import { bindActionCreators } from "redux";
import * as actionCreator from "../../../modules/actions";
import { Container } from "react-bootstrap";
import "../Procedures/procedures.css";
import AddResources from "./addResources";
import DeleteModal from "../deleteModal";
import FilterDropdown from "../../commonComponent/FilterDropdown";
import { getTopResourcesApi } from "../../../modules/services";
import { toast } from "react-toastify";

const TopResources = props => {
  const [publishedPageNumber, setPublishedPage] = useState(1);
  const [publishedDataList, setPublishedList] = useState();
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(0);
  const [modalOpenFrom, setModalOpenFrom] = useState();
  const [sort, setSort] = useState();
  const [csvLoader, setCsvLoader] = useState(false);

  useEffect(() => {
    props.reset();
    props.toggleMainLoader(true);
    if(sort === 'D'){
      props.getPublishedResources(1, sort);
      setPublishedPage(1);
    }else{
      props.getPublishedResources(publishedPageNumber, sort);
      props.toggleMainLoader(true);
      setPublishedPage(publishedPageNumber);
    }
    if (props.responseSuccess) {
      setDeleteIndex(0);
      setDeleteModal(false);
      setShow(false);
    }
  }, [props.match.params.path, props.responseSuccess, sort]);

  const newRename = (value, id,atr1, atr2) => {
    let array = JSON.parse(JSON.stringify(value));
    array = array.map(x => {
      x['idCell'] = x[id]
      x["cellOne"] = x[atr1];
      x["cellTwo"] = x[atr2];
      delete x[atr1];
      delete x[atr2];
      return x;
    });
    return array;
  };

  useEffect(() => {
    if(props.openModal){
      setShow(true);
      setEdit(true);
      setModalOpenFrom("TopResources")
      props.openSearchModal(false)
    }
  }, [])

  useEffect(() => {
    setPublishedList(
      newRename(props.publishedResources.results, "id","title", "priority")
    );
  }, [props.publishedResources.results]);

  const headerPublished = {
    headerId: 'Id',
    headerOne: "Resource Title",
    headerTwo: "Priority",
  };

  const changePagePublished = selected => {
    setPublishedPage(selected);
    props.toggleMainLoader(true);
    props.getPublishedResources(selected, sort);
  };

  const handleBack = () => {
    props.history.push(ROUTES_PATH.CONTENT_MANAGEMENT);
  };
  const handleAddButton = () => {
    props.toggleMainLoader(false);
    setModalOpenFrom("TopResources")
    setShow(true);
    setEdit(false);
  };

  const handleCancelClose = () => {
    setShow(false);
  };

  //Functions for Edit and delete
  const handleDelete = index => {
    setDeleteIndex(index);
    setDeleteModal(true);
  };
  const handleDeleteClose = () => {
    setDeleteIndex(0);
    setDeleteModal(false);
  };
  const handleDeleteQuotes = () => {
    props.toggleMainLoader(true);
    props.deleteResources(deleteIndex);
  };

  const handleUpdate = data => {
    props.contentDetails(data);
    setEdit(true);
    setShow(true);
    setModalOpenFrom("TopResources")
  };

  const handleUpdateClose = () => {
    setShow(false);
    setEdit(false);
  };

  const handelDownloadCsv = () => {
    setCsvLoader(true)
    getTopResourcesApi(publishedPageNumber, sort, true)
    .then(response =>{
      if (response.status === 200 || response.status === 201) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `top_resources.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setCsvLoader(false)
        toast.success("CSV file downloaded successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(err=> {
      console.error(err)
      setCsvLoader(false)
      toast.error("Download Failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    })
  }

  return (
    <>
      <Container
        style={{
          minHeight: "100vh",
          position: "relative",
          maxWidth: "1046px",
        }}
      >
        <Back
          Back={handleBack}
          backIconClassName={"parent-back-icon"}
          backNavItemClassName={"parent-back-nav"}
          className="parent-back"
        />
        <UserManagementNavbar
          title={"Top Resources"}
          isEditButton={false}
          isDeleteButton={false}
          id={"parent-nav-title"}
          className={"parent-navbar"}
          isAddButton={true}
          buttonTitle={"Add Resource"}
          handleAddButton={handleAddButton}
        />

        <div style={{ marginTop: "39px" }}></div>
        <ContentTable
          mainHeader={"Published"}
          secondHeader = {<FilterDropdown  sort = {setSort} is_priority = {true} value = {sort}/>}
          header={headerPublished}
          dataList={publishedDataList}
          rowPerPage={10}
          itemCountFromServer={props.publishedResources.count}
          changePage={changePagePublished}
          pageNumber={publishedPageNumber}
          minHeightClass={"procedures-minHeight"}
          contentHeaderID = {'content-header-id'}
          contentHeaderOne={"content-header1"}
          contentHeaderTwo={"procedures-scheduled-header2"}
          contentHeaderThree={"procedures-scheduled-header3"}
          contentCellOne={"procedures-scheduled-cell1"}
          contentCellTwo={"procedures-scheduled-cell2"}
          contentCellThree={"procedures-scheduled-cell3"}
          viewCellClass={"view-cell-class"}
          viewCell={true}
          onDelete={handleDelete}
          onEdit={handleUpdate}
          isCsvButton = {true}
          handelDownloadCsv = {handelDownloadCsv}
          csvLoader = {csvLoader}
        />
        <Footer className={"content-management-footer"} />
        <AddResources
          show={show}
          contentClassName={"modal-content-quotes"}
          title={edit ? "Update Resource" : "Add Resources"}
          handleCancelClose={edit ? handleUpdateClose : handleCancelClose}
          isEdit={edit}
          isAddResources={false}
          modalOpenFrom = {modalOpenFrom}
        />

        <DeleteModal
          modal={deleteModal}
          handleClose={handleDeleteClose}
          handleDelete={handleDeleteQuotes}
          loader={props.loader}
        />
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    responseSuccess: state.responseSuccess.responseSuccess,
    publishedResources: state.topResources,
    resources: state.resources,
    loader: state.loader.mainLoader,
    openModal : state.modalFromSearch.openModal,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getPublishedResources: actionCreator.getTopResources,
      reset: actionCreator.reset,
      toggleMainLoader: actionCreator.toggleMainLoader,
      approveResources: actionCreator.approveResources,
      deleteResources: actionCreator.deleteTopResources,
      contentDetails: actionCreator.getContentDetails,
      openSearchModal : actionCreator.isOpenModalFromSearch,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TopResources);
